import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { m, AnimatePresence } from "framer-motion";
import { Pagination, ArticleCard } from "@molecules";
import { Container, Dropdown, Text } from "@atoms";

const ArticleList = ({ items, className: _className }) => {
  const types = [
    { uid: "all", title: "All" },
    { uid: "article", title: "Article" },
    { uid: "whitepaper", title: "Whitepaper" },
  ];
  const topics = [{ uid: "all", title: "All" }, ...useCategoriesData().topics];

  const [type, setType] = useState(0);
  const [topic, setTopic] = useState(0);
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter(
        item =>
          (type === 0 || item.type === types[type].uid) &&
          (topic === 0 || item.topics?.includes(topics[topic].uid))
      )
    );
  }, [type, topic]);

  // set topic based on the url parameter

  // const urlFilter =
  // typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;

  // useEffect(() => {
  //   if (urlFilter) {
  //     setTopic(topics.findIndex(t => t.uid === urlFilter));
  //   }
  // }, [urlFilter]);

  const defaults = {
    type: "tween",
    duration: 0.5,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <section className={classNames("relative z-10 py-10 sm:py-20", _className)}>
      <Container>
        {/* filters */}
        <div className="flex flex-wrap gap-3">
          <div className="flex flex-[1_1_100%] items-center space-x-2 sm:flex-initial">
            <Text variant="label" className="text-teal">
              Type
            </Text>
            <Dropdown model={[type, setType]} options={types} size="sm" />
          </div>
          <div className="flex flex-[1_1_100%] items-center space-x-2 sm:flex-initial">
            <Text variant="label" className="text-teal">
              Topic
            </Text>
            <Dropdown model={[topic, setTopic]} options={topics} size="sm" />
          </div>
        </div>
        {/* filtered items */}
        <div className="mt-10 flex flex-col items-center gap-10">
          <AnimatePresence mode="wait">
            {!!filteredItems?.length && (
              <Pagination
                data={filteredItems}
                pageSize={9}
                component={ArticleCard}
                variants={variants}
                showNavigation={false}
                className="grid w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3"
              />
            )}
            {!filteredItems?.length && (
              <m.div
                key="no-results"
                initial="exit"
                animate="enter"
                exit="exit"
                variants={variants}
                className="flex h-20 flex-col items-center justify-center"
              >
                Your selection did not return any results.
              </m.div>
            )}
          </AnimatePresence>
        </div>
      </Container>
    </section>
  );
};

ArticleList.defaultProps = {};

export default ArticleList;
