import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query CategoriesQuery {
    craft {
      categories {
        title
        uid
        groupHandle
      }
    }
  }
`;

const dataResolver = ({ categories }) => {
  return categories.reduce((a, b) => {
    // get group name
    const { groupHandle } = b;
    // spread existing group (if there is one)
    const group = [...(a[groupHandle] || []), b];
    // add the updated group to the object
    return { ...a, [groupHandle]: group };
  }, {});
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
